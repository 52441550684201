/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply overflow-y-scroll;
    scroll-behavior: smooth;
  }

p  {
  line-height: 1.6rem;
}  

/* Flexbox fallback for older browsers */
@supports not (display: flex) {
  .flex {
    display: block;
  }
  .flex > * {
    display: inline-block;
    vertical-align: top;
  }
}
/* Gap fallback for Safari 10.1-13.x */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .gap-2 {
      margin: -0.25rem;
    }
    .gap-2 > * {
      margin: 0.25rem;
    }
    .lg\:gap-32 {
      margin: -4rem;
    }
    .lg\:gap-32 > * {
      margin: 4rem;
    /* Fallback for .btn gap in Safari */
    .btn > * {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
    .btn > *:first-child {
      margin-left: 0;
    }
    .btn > *:last-child {
      margin-right: 0;
    }
    /* Fallback for flex items center in Safari */
    .items-center {
      display: table;
      width: 100%;
      height: 100%;
    }
    .items-center > * {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

/* Fallback for browsers that don't support :where() and complex selectors */
.menu li a,
.menu li details > summary {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: start;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  text-wrap: balance;
}

/* Exclude .btn class from the fallback styles */
.menu li a:not(.btn),
.menu li details > summary:not(.btn) {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

/* Fallback styles for dialog element */
@supports not (selector(:modal)) {
  dialog {
    display: none;
  }

  dialog[open] {
    display: block;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

@supports not (selector(dialog)) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal:not([open]) {
    display: none;
  }

  .modal-box {    
    padding: 1rem;
    border-radius: 0.5rem;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
}

}



@media (max-width: 640px) {
  .responsive-table {
    display: block;
  }

  .responsive-table thead {
    display: none;
  }

  .responsive-table tbody {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .responsive-table tr {
    display: flex;
    flex-direction: column;
    border: 1px solid hsl(var(--b2));
    border-radius: var(--rounded-box, 1rem);
    padding: 1rem;
    background-color: hsl(var(--b1));
    box-shadow: var(--tw-shadow-md);
    width: 100%;
  }

  .responsive-table td {
    display: flex;
    padding: 0.5rem 0;
    border: none;
  }

  .responsive-table td::before {
    content: attr(data-label);
    font-weight: bold;
    width: 40%;
    margin-right: 1rem;
  }

  .responsive-table td:last-child {
    border-bottom: none;
  }
}       

/* Add this at the end of the file */

/* Prevent vertical scrolling when webcam is on for smaller viewports */
@media (max-width: 1023px) {
  body.webcam-active {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
}